import React from "react";
import Doctor from "./doctor-book-appointment-4.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "./BookAppointment.css";

function BookAppointment() {
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  return (
    <div className="ba-section" id="appointment">
      <div className="ba-image-content">
        <img src={Doctor} alt="Doctor Group" className="ba-image1" />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Why Choose Care Tribune</span>
        </h3>
        <p className="ba-description">
        <ul>
          <li>100% HIPAA compliant process for maximum security and confidentiality</li>
          <li>98% success rate in first submission claims payment with a certified coding team</li>
          <li>Continuous and rigorous follow-ups on denied and pending claims to minimize disruptions and operating costs</li>
          <li>Denial follow-up and resolution handled by industry experts, reducing staffing issues</li>
          <li>Clients benefit from almost 70% savings on operating costs, eliminating the need for staff training and updates</li>
          <li>Quick responses to billing queries facilitated by dedicated practice managers</li>
          <li>Shortest turnaround time and faster reimbursements through cycle management by billing specialists</li>
          <li>Overall, a streamlined and efficient approach to medical billing providing a comprehensive solution</li>
        </ul>
        </p>

        {/* <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Best Professional Doctors
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Emergency Care
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> 24/7 Support Live Chat
        </p>
        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Enrollment Easy and Quick
        </p> */}

        <button
          className="text-appointment-btn"
          type="button"
          onClick={handleBookAppointmentClick}
        >
          <FontAwesomeIcon icon="fa-regular fa-address-book" /> Contact Us
        </button>
      </div>
    </div>
  );
}

export default BookAppointment;
