import React,  { useState } from "react";
import "./Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";

function Footer() {

  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              Care <span className="ft-sign">Tribune</span>
            </p>
            <p className="ft-description">
            Care Tribune LLC stands out as a prominent medical billing firm located in 10024 Newington DR Orlando, FL 32836.
            </p>
          </div>

          {/* <SubscribeNewsletter /> */}
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Medical Billing and Coding</a>
            </li>
            <li>
              <a href="#services">Credentialing Service</a>
            </li>
            <li>
              <a href="#services">Revenue Cycle Management</a>
            </li>
            <li>
              <a href="#services">Eligibility Verification</a>
            </li>
            <li>
              <a href="#services">Account Receivable</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title"></p>
          <br/>
          <ul className="ft-list-items" style={{marginTop: "10px"}}>
            <li>
              <Link to={"/legal"}>Virtual Assistant</Link>
            </li>
            <li>
              <Link to={"/legal"}>Denial Management</Link>
            </li>
            <li>
              <Link to={"/legal"}>Prior Authorization</Link>
            </li>
            <li>
              <Link to={"/legal"}>Medical Practice Consulting</Link>
            </li>
            <li>
              <Link to={"/legal"}>Web Development & Marketing</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:admin@caretribune.com">admin@caretribune.com</a>
            </li>
            <li>
              <a href="tel:+1 (540) 299-7399">+1 (540) 299-7399</a>
            </li>
            <li>
              <p style={{width: "220px", fontSize: "10px"}}>By providing my phone number to Care Tribune LLC. I agree and acknowledge that Care Tribune LLC. may send text messages to my wireless phone number for any purpose. Message and data rates may apply.<br/>We will only send one SMS as a reply to you, and you will be able to Opt-out by replying “STOP”. For more information on how your data will be handled please visit <a style={{cursor: "pointer"}} onClick={openPopup}>(PRIVACY POLICY)</a></p>
            </li>
            {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={closePopup}>
              &times;
            </span>
            <p style={{width: "220px", fontSize: "10px"}}>No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</p>
          </div>
        </div>
      )}
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2023 Care Tribune. All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://linkedin.com/in/"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://facebook.com/"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://x.com/"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
