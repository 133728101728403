import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Faqs.css";
import { Grid } from "@mui/material";
export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="faq_main">
      <Grid container justifyContent="center" alignItems={"center"}>
        <Grid item xs={11} md={10} lg={7.5}>
          <p className="faqs">Frequently Asked Questions</p>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className="accordian"
            sx={{ marginTop: "1rem" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0 }}
                className="accordian_typo"
              >
                What is medical billing?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Medical billing is collecting and processing claims for
                different types of healthcare services. It involves tracking
                patient information such as insurance coverage, deductible
                amounts, and other details about a patient's medical history to
                ensure that all patients receive appropriate care. Also, a
                baseline of charges is established for each patient who received
                care to calculate the amount owed by insurance companies and
                third parties. Medical billing is a crucial part of the
                healthcare system, as it ensures that all insurance companies
                and patients receive their respective share of the cost of
                treatment.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className="accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0 }}
                className="accordian_typo"
              >
                What services do you offer as a revenue cycle management company?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We offer various revenue cycle management services, including billing,
                coding, and collections. We also offer specialized services such
                as Credentialing, Denial Management, Account Receivable, and
                Virtual Medical Scribes
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className="accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0 }}
                className="accordian_typo"
              >
                What are the qualifications of your medical billing and coding
                experts?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our team is composed of highly trained and certified medical
                billing and coding experts with years of experience in the
                industry.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className="accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0 }}
                className="accordian_typo"
              >
                How do I get started with your medical billing services?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Contact us today to schedule a consultation, and we'll help you
                get started with our services.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            className="accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel4bh-header"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0 }}
                className="accordian_typo"
              >
                How does Care Tribune's medical billing service help with
                insurance claim disputes?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Our team of professionals is experienced in handling insurance providers and is capable of addressing any disputes or challenges that may emerge. With our knowledge and expertise, we guarantee accurate and efficient processing of your claims. We collaborate closely with our clients to promptly resolve any issues that may arise.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            className="accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography
                sx={{ width: "100%", flexShrink: 0 }}
                className="accordian_typo"
              >
                Kann ich meine Klimaanlage desinfizieren lassen?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Ein herkömmlicher Klimaservice enthält keine Desinfektion der
                Klimaanlage, auf Wunsch kann dieser Service aber zusätzlich
                gebucht werden.
                <br />
                Hier wird das Lüftungssystem des Autos von Bakterien, Mikroben
                und Pilzen gereinigt, sodass dadurch verursachte Gerüche oder
                auch Nikotin-Gerüche entfernt werden können. Vor allem für
                Allergiker ist eine zusätzliche Desinfektion vorteilhaft, so
                können aber auch Allergien vorgebeugt und eine unnötige
                Belastung der Atemwege vermieden werden.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        </Grid>
      </Grid>
    </div>
  );
}
