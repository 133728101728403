import React from "react";
import Doctor from "./doctor-group.png";
import SolutionStep from "./SolutionStep";
import "./About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
        Engaging with Care Tribune for practice management brings advantages like a proactive billing process, quicker payments, and efficient denials management.

Our all-encompassing medical billing service alleviates providers of administrative tasks and supports the growth of their practice. This includes comprehensive services such as credentialing, coding, and participation in quality payment programs.

Allow us to manage patient billing, claims submission, and payer enrollment challenges, allowing you to concentrate on your core strengths.
        </p>

        {/* <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Choose a Specialist"
          description="Find your perfect specialist and book with ease at Health Plus. Expert doctors prioritize your health, offering tailored care."
        />

        <SolutionStep
          title="Make a Schedule"
          description="Choose the date and time that suits you best, and let our dedicated team of medical professionals ensure your well-being with personalized care."
        />

        <SolutionStep
          title="Get Your Solutions"
          description="Our experienced doctors and specialists are here to provide expert advice and personalized treatment plans, helping you achieve your best possible health."
        /> */}
      </div>
    </div>
  );
}

export default About;
