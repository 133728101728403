import React, { useEffect, useState } from "react";
import Doctor from "./doctor-picture.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";
import { useNavigate  } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  return (
    <div className="section-container" id="home">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">Efficiency. Accuracy. Growth</p>
          <h2 className="text-title">
            CARE TRIBUNE LLC.
          </h2>
          <p className="text-descritpion">
          Care Tribune LLC stands out as a prominent medical billing firm located in Orlando, Florida. Our array of medical billing services is designed to assist you in optimizing revenue and enhancing your overall financial performance. Feel free to get in touch with us today for further information.
          </p>
          <button
            className="text-appointment-btn"
            type="button"
            onClick={handleBookAppointmentClick}
          >
            <FontAwesomeIcon icon="fa-regular fa-address-book" /> Contact Us
          </button>
          <div className="text-stats">
            <div className="text-stats-container">
              <p>100+</p>
              <p>Clients</p>
            </div>

            {/* <div className="text-stats-container">
              <p>50+</p>
              <p>Expert Doctors</p>
            </div> */}

            <div className="text-stats-container">
              <p>10+</p>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>
    </div>
  );
}

export default Home;
