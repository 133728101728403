import React, { useState } from 'react';
import "./AppointmentForm.css";
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const AppointmentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_jlh1dra',       // Replace with your EmailJS service ID
      'template_yo578ec',      // Replace with your EmailJS template ID
      e.target,
      'LMpYfg3SLz_n4RxQa'           // Replace with your EmailJS user ID
    ).then((result) => {
        console.log('Form submitted:', result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log('Error:', error.text);
        alert('An error occurred, please try again.');
      });
  };

  return (
    <div style={{ paddingTop: "2%" }}>
      <Link to={'/'}><ClearIcon /></Link>
      <h1 className='contact-heading'>Contact Us</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <label className="form-label">
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} className="form-input" />
        </label>
        <label className="form-label">
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-input" />
        </label>
        <label className="form-label">
          Message:
          <textarea name="message" value={formData.message} onChange={handleChange} className="form-textarea" />
        </label>
        <button type="submit" className="form-button">Submit</button>
      </form>
    </div>
  );
};

export default AppointmentForm;
