import About from '../components/About/About';
import BookAppointment from '../components/Appointment/BookAppointment';
import Faqs from '../components/Faqs/Faqs';
import Footer from '../components/Footer/Footer';
import Home from '../components/Home/Home';
import Navbar from '../components/Navbar/Navbar';
import Services from '../components/Services/Services';

function MainHome() {
  return (
    <div className="App">
    <Navbar />
    <Home />
    <Services />
    <About/>
    <Faqs />
    <BookAppointment />
    <Footer/>
    </div>
  );
}

export default MainHome
