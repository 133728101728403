import React from "react";
import "./Service.css";

function Services() {
  const data = [
    {
      cardImage: "./Assets/ser2.png",
      cardHeading: "Medical Billing and Coding",
      cardPara:
        "Our system analyzes data following medical coding guidelines, and our tailored module meticulously identifies and rectifies 99% of claim errors before submission. Our reporting process involves a thorough manual examination of the data to ensure accuracy.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser1.png",
      cardHeading: "Credentialing Service",
      cardPara:
        "Our company caters to both medical and non-medical specialties nationwide in the United States. Our experienced credentialing professional specializes in aiding providers with credentialing issues and enrollment with preferred payers.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser3.png",
      cardHeading: "Virtual Assistant Services",
      cardPara:
        "answering patient calls, scheduling appointments, scheduling follow-ups, managing faxes and registrations, monitoring medication schedules, coordinating lab tests, verifying coverage, handling upfront payments, and sending patient statements.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser4.png",
      cardHeading: "Eligibility Verification",
      cardPara:
        "If we don't check if patients are eligible for benefits properly and authorize their coverage correctly, it can cause more denied claims, late payments, extra paperwork, and delayed patient care.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser7.png",
      cardHeading: "Account Receivable",
      cardPara:
        "Our services for managing accounts receivable efficiently handle outstanding balances in your practice by pursuing payments from both patients and payers as needed. Timely posting of payments is crucial for a seamless billing process.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser6.png",
      cardHeading: "Denial Management",
      cardPara:
        "With the Denial Management service, you can pinpoint the reasons for claim denials, address and rectify them, and resubmit if required. This leads to a reduced denial rate and increases the likelihood of reimbursement.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser1.png",
      cardHeading: "Prior Authorization",
      cardPara:
        "Our team possesses a comprehensive understanding of contractual requirements set by payers. A team member is responsible for assessing and confirming an individual's eligibility, securing insurance benefits, and ensuring compliance with pre-certification, authorization, and referral requirements.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser8.png",  
      cardHeading: "Medical Practice Consulting",
      cardPara:
        "We enhance the effectiveness and financial success of healthcare professionals' practices by offering services such as financial analysis, staff training, marketing strategies, and technological implementations. A consultant specializing in medical practices can optimize operations and enhance patient care.",
      cardBtn: "Explore",
    },
    {
      cardImage: "./Assets/ser8.png",  
      cardHeading: "Web Development & Marketing",
      cardPara:
        "Enhance your online presence with our Web Development & Marketing expertise. From custom website design to SEO optimization, our comprehensive solutions deliver impactful results. Whether launching a new site or refining your strategy, partner with us for a dynamic online presence that captivates your audience and maximizes brand impact.",
      cardBtn: "Explore",
    },
  ];

  return (
    <div id="services">
      <div className="container">
      <h3 className="service-title">
          <span>Services</span>
        </h3>
        <div className="about_section">
          {data.map((e) => {
            return (
              <div className="about_card">
                <div className="card_img">
                  <img className="about_img" src={e.cardImage} alt="" />
                  {/* <button className="card_button"></button> */}
                </div>
                <h1 className="card_heading">{e.cardHeading}</h1>
                <p className="cardpara">{e.cardPara}</p>
                <button className="card_button">{e.cardBtn}</button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Services;
